
















import service from '@/services/app-http-client';
import { Loading } from '@/modules/auth/store/auth.service';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import { Component, Vue } from 'vue-property-decorator';
import { appModule } from '../../../store/modules/app.module';
import contentModule from '../../../store/modules/content.module';

@Component({
  name: 'checkout',
  layout: 'AppMain',
  metaInfo: {
    title: 'Check-in confirmation',
  },
})
export default class CheckOut extends Vue {
  pricingOrder = ['Free Trial', 'basic', 'standard', 'premium', 'enterprise'];
  loading = true;
  documentCheckbox = false;

  get content() {
    const contentArr: any[] = contentModule.subContent.filter((sub: any) => sub.fields.price !== 0);
    let priceArr: any[] = contentArr.sort((a, b) => {
      return this.pricingOrder.indexOf(a.fields.name.toLowerCase()) - this.pricingOrder.indexOf(b.fields.name.toLowerCase());
    });
    return priceArr;
  }

  get plan() {
    if (this.$route.query.plan) return this.content.find((val) => val?.fields?.name === this.$route.query.plan).fields;
    return { price: this.$route.query.amount, checkInCredits: this.$route.query.credits, name: 'Top up', description: 'Credit top up' };
  }

  get returnURL() {
    return `${appModule.api}/v1/api/billing/nty`;
  }

  payNow() {
    const button = document.getElementsByClassName('wpwl-button') as any;
    console.log(button);
    button[0].click();
  }

  @Loading
  async created() {
    const loading = this.$vs.loading({
      type: 'circles',
      color: 'primary',
      text: 'Loading checkout...',
    });
    let res;
    if (this.$route.query.plan) {
      const plan = this.$route.query.plan as string;
      res = await service.get(`billing/subscription/${plan}`);
    } else {
      res = await service.post(`billing/topup`, {
        amount: parseInt(this.$route.query.amount as string, 10),
        credits: parseInt(this.$route.query.credits as string, 10),
      });
    }
    window.wpwlOptions = {
      style: 'plain',
      requireCvv: true,
      // forceCardHolderEqualsBillingName: true,
      iframeStyles: {
        'card-number-placeholder': {
          'border-color': 'none',
        },
        'cvv-placeholder': {
          'border-color': 'none',
        },
      },
      showCVVHint: true,
      brandDetection: true,
      useSummaryPage: false,
      showLabels: true,
      showPlaceholders: false,
      spinner: {
        color: 'red',
      },
      onReady: () => {
        this.loading = false;
        loading.close();
      },
      onError: () => {
        this.loading = false;
        loading.close();
        this.$swal({
          title: 'Oops! Something went wrong...',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'Okay',
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.go(-1);
          }
        });
      },
    };
    const peachUri = process.env.VUE_APP_MODE === 'staging' ? 'test.oppwa.com' : 'oppwa.com';

    const script = document.createElement('script');
    script.src = `https://${peachUri}/v1/paymentWidgets.js?checkoutId=${res.data.id}`;
    const tag = document.getElementById('checkout');
    if (tag) tag.appendChild(script);
  }
}
